<template>
  <v-main>
    <h1 class="halamanJudul">Dashboard</h1>
    <v-row>
      <v-col cols="6">
        <v-card>
          <v-card-title class="cardtitle" style="background-color: #8c7ae6">
            The Business Process Of FFB Transportation
          </v-card-title>
          <v-img :src="gambar1" height="100%"></v-img>
          <v-card-title style="background-color: #8c7ae6"> </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card>
          <v-card-title class="cardtitle" style="background-color: #8c7ae6">
            Logical model of Mobility DW
          </v-card-title>
          <v-img :src="gambar2" max-height="100%"></v-img>
          <v-card-title style="background-color: #8c7ae6"> </v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
export default {
  name: "Dashboard",
  data() {
    return {
      gambar1: {
        src: require("../assets/alurpanen.jpg"),
      },
      gambar2: {
        src: require("../assets/CDesign.jpg"),
      },
    };
  },
};
</script>

<style scoped>
</style>
